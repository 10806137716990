@import '../../variables';

.levels-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
	width: max-content;
	margin-left: auto;
	margin-right: auto;
}
