@import '../../../../../variables';

.memory-game {
	height: 100%;
	width: 100%;

	.title {
		margin-bottom: 15px;

		p {
			margin-bottom: 0;
		}
	}

	.cards-container {
		width: 72%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-left: auto;
		margin-right: auto;

		.card-container {
			width: 33.33%;
			padding: 5px;
		}
	}
}

@media (max-height: 550px) {
	.title {
		margin-bottom: 7px;

		p {
			margin-top: 5px;
		}
	}
}
