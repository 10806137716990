@import '../../variables';

.btn {
	display: inline-block;
	background-color: $color-main;
	font-size: 20px;
	font-weight: 600;
	height: 50px;
	line-height: 42px;
	color: $color-white;
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	border: 4px solid rgba($color-main, .4);
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box;
	border-radius: 23px;

	&.full-width {
		width: 100%;
	}

	&.box-shadow {
		box-shadow: 0 3px 10px rgba(0,0,0,.5);
	}

	&.outline {
		background-color: transparent;
		color: $color-white;
		border: 1px solid $color-white;
		font-size: 12px;
		height: 38px;
		line-height: 38px;
	}

	&.inverted {
		background-color: $color-white;
		color: $color-main;
		border-color: rgba($color-white, .4);
	}
}

//@media (max-height: 574px) {
//	.btn {
//		height: 40px;
//		line-height: 40px;
//		font-size: 16px;
//
//		&.outline {
//			height: 31px;
//			line-height: 31px;
//		}
//	}
//}
