@import "../../variables";

.prizes-banner {
	padding: 24px 12px 8px 12px;

	.banner-content {
		display: flex;
		align-items: center;
		gap: 6px;
		background-color: rgba($color-main, .15);
		padding: 8px 4px;
		min-height: 52px;
		border-radius: 10px;
	}

	.prize-banner {
		display: flex;
		align-items: center;
		gap: 4px;
		width: 50%;

		&-image {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;

			img {
				width: 100%;
			}
		}

		&-info {
			flex: 1;
		}

		&-title {
			font-size: 16px;
			line-height: 1.1;
			font-weight: 600;
			color: $color-white;
		}

		&-description {
			font-size: 12px;
			line-height: 1.1;
			color: $color-white;
			margin-top: 4px;
		}
	}
}
