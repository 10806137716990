@import "../../variables.scss";

.prizes-component {
	padding-bottom: 30px;

	.prizes-image {
		display: block;
		margin: 0 auto 19px;
	}

	.prizes {
		display: flex;
		flex-direction: column;
		gap: 21px;
		align-items: center;

		.prize-item {
			&:nth-child(2) {
				.prize-image {
					justify-content: flex-end;

					img {
						max-height: 105px;
					}
				}
			}
		}
	}

	.prizes-btn {
		display: block;
		width: 251px;
		margin-top: 35px;
		margin-left: auto;
		margin-right: auto;
	}
}
