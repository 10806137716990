@import "../../variables";

.profile-component {
	.user-avatar {
		position: relative;
		width: 94px;
		margin-left: auto;
		margin-right: auto;

		& > img {
			display: block;
			max-width: 100%;
		}

		.user-level {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: -10px;
			right: -10px;
			height: 41px;
			width: 41px;
			background-image: url("./img/bg-level.png");
			background-size: cover;
			background-repeat: no-repeat;
			padding: 5px;

			img {
				display: block;
				max-width: 100%;
				margin-top: 3px;
			}
		}
	}

	.user-status {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		margin-top: 24px;

		p {
			display: block;
			width: 173px;
			padding: 6px 45px 6px 25px;
			background-color: rgba($color-white, 0.35);
			border: 3px solid $color-incorrect;

			&.active {
				border: 3px solid $color-correct;
				background-image: url("../../resources/img/icon-check.png");
				background-repeat: no-repeat;
				background-position: 91% center;
			}
		}
	}

	.btn-group {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 251px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 32px;
	}
}
