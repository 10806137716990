@import '../../../../../variables';

.drag-game {

	.game-wrapper {
		width: 74%;
		margin-left: auto;
		margin-right: auto;

		.original {
			width: 100%;
			margin-bottom: 10px;
			background-color: $color-bg-question;
			text-align: center;

			img {
				width: 120px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.answers-list {
			display: flex;
			justify-content: space-between;
			width: 90%;
			margin-left: auto;
			margin-right: auto;

			.answer {
				padding-left: 6px;
				padding-right: 6px;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
