@import "../../../variables";

footer {

	.stats-wrapper {
		padding: 10px 12px;
	}

	.powered-by {
		height: 62px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-main;

		img {
			height: 38px
		}
	}
}
