@import '../../../../../../variables';

.scratch-pregame {
	.title {
		margin-bottom: 35px;
	}

	.cards-list {
		max-width: 285px;
		display: grid;
		grid-template-columns: repeat(2, 120px);
		gap: 15px;
		justify-content: center;
		align-items: center;
		position: relative;
		margin-left: auto;
		margin-right: auto;

		li {
			overflow: hidden;
			position: relative;

			&.not-win {
				pointer-events: none;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($color-black, .5);
					z-index: 1;
				}
			}

			.scratch-card-wrapper {
				position: relative;
			}

			.prize {
				position: absolute;
				inset: 0;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				width: 100%;
				height: 100%;
				padding-left: 6px;
				padding-right: 6px;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				p {
					font-size: 28px;
					line-height: 1;
					font-weight: 700;
					color: $color-correct;
				}

				span {
					font-size: 20px;
					line-height: 0.9;
					color: $color-correct;
				}
			}
		}
	}
}
