@import '../../../../variables';

.game-timer {
	width: 102px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 9px;
	padding-right: 8px;
	height: 40px;
	border-radius: 10px;
	background-color: rgba($color-main, 0.15);
	color: $color-text;

	img {
		width: 27px;
		margin-bottom: -3px;
	}

	.time-left {
		width: 54px;
		margin-bottom: -2px;
		font-size: 18px;
		font-weight: 600;
		text-shadow: 0px 3px 4px #00000033;
	}
}
