.loader-container {
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden;

	.loader {
		width: 100%;
		height: 100%;
		position: relative;

		& > div {
			animation: spin 1s linear infinite;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			width: 120px;
			height: 120px;
			border: 20px solid black;
			border-top-color: transparent;
			border-radius: 50%;
		}
	}
}

@keyframes spin {
	0% { transform: translate(-50%,-50%) rotate(0deg); }
	100% { transform: translate(-50%,-50%) rotate(360deg); }
}
