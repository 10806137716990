@import "../../../variables";

.result-component {
	padding-left: 20px;
	padding-right: 20px;

	.prize-results {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 211px;
		height: 89px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 45px;
		background-image: url("../../../resources/img/bg-border.png");
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;

		&.small {
			width: 146px;
			height: 89px;
			background-image: url("../../../resources/img/bg-border-small.png");
		}

		h3 {
			margin-bottom: -26px;
			font-size: 44px;
			font-weight: 700;
			line-height: 28.6px;
			color: $color-text;
		}

		p {
			display: block;
			width: 100%;
			font-size: 28px;
			line-height: 18px;
			font-weight: 400;
		}
	}

	.level-results {
		position: relative;
		margin-bottom: 30px;
		text-align: center;

		.level-image {
			position: relative;
			max-width: 160px;
			z-index: 1;
		}

		.bg-level {
			max-width: 100%;
			position: absolute;
			top: 10%;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}

		p {
			font-size: 22px;
			line-height: 26px;
			font-weight: 700;
		}

		span {
			font-size: 16px;
			line-height: 18px;
			font-weight: 500;
			color: $color-main;
		}
	}

	.trophy-results {
		margin-bottom: 30px;
		text-align: center;

		img {
			width: 164px;
		}
	}

	.btn-again {
		display: block;
		max-width: 251px;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;

		&.inactive {
			//.btn {
			//	background-color: $color-white;
			//	color: $color-bg;
			//}
		}

		.countdown {
			display: inline-block;
			width: 85px;
			margin-left: 5px;
		}
	}

	.btn-next {
		display: block;
		margin-top: 20px;
	}
}
