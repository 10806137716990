@import '../../../../variables';

.player-stats-component {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 6px 10px;
	background-color: rgba($color-white, .6);
	border-radius: 10px;

	.home-button {
		position: absolute;
		top: -3px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 49px;
		width: 49px;
		background-color: $color-white;
		border-radius: 50%;
		box-shadow: 0px 4px 6px 0px #00000033;

		img {
			width: 42px;
		}
	}

	.stats-item {
		display: flex;

		&.left {
			.icon-wrapper {
				box-shadow: -4px 0px 4px 0px #00000040 inset;
			}

			.stats-info {
				margin-left: -40px;
				padding-left: 46px;
				padding-right: 4px;
				box-shadow: -4px 0px 5px 0px #00000026 inset;
			}
		}

		&.right {
			.icon-wrapper {
				box-shadow: 4px 0px 4px 0px #00000040 inset;
			}

			.stats-info {
				margin-right: -40px;
				padding-right: 46px;
				padding-left: 4px;
				box-shadow: 4px 0px 5px 0px #00000026 inset;
				text-align: right;
			}
		}

		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;
			padding: 4px 5px 0;
			border-radius: 10px;
			background-color: $color-correct;
			z-index: 1;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.stats-info {
			width: 109px;
			padding-top: 3px;
			padding-bottom: 3px;
			background-color: $color-white;
			border: 1px solid $color-correct;
			border-radius: 10px;

			span {
				display: block;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 600;
				line-height: 19px;
				color: $color-text-gray;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;

				&.small {
					font-size: 12px;
					font-weight: 400;
					line-height: 14.22px;
				}
			}
		}
	}
}
