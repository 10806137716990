@import '../../../../../variables';

.knock-pregame {
	.title {
		margin-bottom: 35px;
	}

	.game-wrapper {
		padding-left: 12.5px;
		padding-right: 12.5px;
	}

	.cards-list {
		max-width: 285px;
		display: grid;
		grid-template-columns: repeat(2, 120px);
		gap: 15px;
		justify-content: center;
		align-items: center;
		position: relative;
		margin-left: auto;
		margin-right: auto;

		li {
			overflow: hidden;
			position: relative;

			&.not-win {
				pointer-events: none;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($color-black, .3);
					z-index: 1;
				}
			}
		}

		.knock-card {
			position: relative;
			transition: all .8s;
			transform-style: preserve-3d;
			padding-bottom: 100%;
			border-radius: 0;

			.side {
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
			}

			.front {
				background-size: cover;
				background-position: center;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			.back {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				transform: rotateY(180deg);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				.prize {
					position: absolute;
					inset: 0;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;
					width: 100%;
					height: 100%;
					padding-left: 6px;
					padding-right: 6px;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					p {
						font-size: 28px;
						line-height: 1;
						font-weight: 700;
						color: $color-correct;
					}

					span {
						font-size: 20px;
						line-height: 0.9;
						color: $color-correct;
					}
				}
			}
		}

		.knock-card.flipped {
			transform: rotateY(180deg);
		}
	}
}
