@import '../../../../../variables';

.differences-game {

	.game-wrapper {
		width: 74%;
		margin-left: auto;
		margin-right: auto;

		.original {
			position: relative;
			margin-bottom: 20px;
			box-shadow: 0px 4px 4px 0px #00000040;

			img {
				max-width: 100%;
				display: block;
			}
		}

		.different {
			position: relative;
			box-shadow: 0px 4px 4px 0px #00000040;

			img {
				max-width: 100%;
				display: block;
			}
		}

		.difference {
			position: absolute;
			width: 6.8%;
			padding-bottom: 6.8%;

			&.selected {
				border: 1px solid red;
				border-radius: 100%;
				box-sizing: content-box;
			}
		}
	}
}
