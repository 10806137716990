//Colors
$color-white: #ffffff;
$color-black: #000000;
$color-bg: #FF6643;
$color-main: #200807;
$color-text: #ffffff;
$color-text-dark: #3f3f40;
$color-text-gray: #616161;
$color-error: #dc3545;
$color-valid: #28a745;
$color-correct: #449971;
$color-incorrect: #c31919;
$color-bg-question: #E6E6E6;
$color-special-title: #a62aa2;
$color-red: #DC1F1B;

//Fonts
$font-body: "Rubik", serif;
