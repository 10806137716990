@import "../../variables.scss";

.text-pages {
    background-color: $color-main;
    min-height: 100%;
    padding: 24px 20px;
		color: $color-white;

		.page-title {
			position: relative;
			text-align: center;

			a {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;

				img {
					display: block;
					width: 33px;
				}
			}
		}

		& > * {
			color: $color-white;
		}

		span,
		strong {
			color: $color-white!important;
		}

    p {
        margin: 16px 0;
        line-height: 20px;
				color: $color-white;

        &:first-of-type {
            margin-top: 20px;
        }
    }
}

