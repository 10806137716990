@import "../../variables";

.intro-component {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding-top: 27px;
	padding-bottom: 37px;
	padding-left: 50px;
	padding-right: 50px;

	.logo {
		display: block;
		max-width: 140px;
		margin: 0 auto;
	}

	.caption {
		max-width: 241px;
		margin: 40px auto 37px;
		z-index: 1;

		& > div {
			text-align: center;
			font-size: 24px;
			line-height: 34px;
			font-weight: 400;
			color: $color-white;

			strong {
				font-weight: 700;
			}

			span {
				color: $color-main;
			}

			.special {
				font-weight: 500;
			}
		}
	}

	.btn-wrapper {
		z-index: 1;
	}

	.loader-react {
		.bottom-shapes {
			& > img {
				opacity: 1;
				animation: none;
			}
		}
	}
}
