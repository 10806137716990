
.unsubscribe-msisdn {
	min-height: 201px;

	.description {
		min-height: 40px;
		margin-bottom: 10px;
	}

	.inputs-wrapper {
		min-height: auto;
	}
}

@media (max-height: 574px) {
	.unsubscribe-msisdn {
		min-height: 157px;
	}
}
