@import '../../variables';

.about-component {
	margin-top: auto;
	margin-bottom: auto;
	overflow: auto;

	.logo {
		display: block;
		max-width: 140px;
		margin: 27px auto;
	}

	.about-slider {
		text-align: center;
		padding-left: 37px;
		padding-right: 37px;

		.slick-dots {
			bottom: -7px;
		}

		.item {
			//height: 288px;
			position: relative;

			&:focus {
				outline: 0;
			}

			&.first-item {
				.prizes {
					margin-top: 34px;
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 22px;

					.prize-item {
						&:nth-child(2) {
							.prize-image {
								img {
									max-height: 87px;
								}
							}
						}
					}
				}
			}

			&.second-item {
				.trophy {

					img {
						margin: 0 auto;
						max-width: 100%;
					}
				}
			}

			&.third-item {
				.slide-description {
					margin-bottom: 26px;
				}
			}

			.slide-header {
				font-size: 22px;
				line-height: 22px;
				color: $color-white;
				font-weight: 700;
			}

			.slide-description {
				font-size: 16px;
				line-height: 16px;
				color: $color-main;
				font-weight: 500;
				margin-top: 11px;
			}
		}
	}

	.btn-slider {
		display: block;
		width: 259px;
		margin-top: 33px;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-warning {
		text-decoration: none;

		.btn {
			height: auto;
			padding-top: 8px;
			padding-bottom: 8px;
			line-height: 14px;
		}

		.warning-text {
			display: block;
		}
	}
}
