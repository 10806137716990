@import '../../../variables';

.watch-video-component {
	.video-wrapper {
		position: relative;
		max-height: 300px;
		margin: 0 auto;
		margin-top: 20px;
		overflow: hidden;
		aspect-ratio: 16 / 9;
	}

	.btn-group {
		display: flex;
		flex-direction: column;
		gap: 17px;
		width: 251px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}
}

.modal-watch-video {
	.modal-content {
		.modal-message {
			max-width: 215px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			color: $color-correct;
		}
	}
}
