@import "../../variables";

.prize-item {
	width: 286px;
	min-height: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-repeat: no-repeat;

	&.v1 {
		background-image: url("img/bg-1.png");
	}

	&.v2 {
		background-image: url("img/bg-2.png");
	}

	&.v3 {
		min-height: 128px;
		background-image: url("img/bg-3.png");
	}

	.prize-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 97px;

		img {
			max-height: 63px;
			max-width: 100%;
		}
	}

	.prize-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		flex-wrap: wrap;
		text-align: left;
		margin-right: 10px;
		overflow: hidden;

		.prize-title {
			font-size: 24px;
			line-height: 24px;
			font-weight: 600;
			color: $color-white;
		}

		.prize-description {
			margin-top: 7px;
			font-size: 16px;
			line-height: 16px;
			font-weight: 400;
			color: $color-white;
		}

		.prize-subtitle {
			font-weight: 300;
			font-size: 9px;
			line-height: 9px;
		}
	}
}
