@import '../../variables';

.level-item {
	position: relative;
	height: 100px;
	width: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-image: url("img/bg-level.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 10px;
	padding-top: 6px;
	box-shadow: 0px 0px 8px 0px #000000A6;

	&:first-child {
		.level-image {
			img {
				width: 50px;
			}
		}
	}

	&:nth-child(2) {
		.level-image {
			img {
				width: 41px;
			}
		}
	}

	&:nth-child(3) {
		.level-image {
			img {
				width: 53px;
			}
		}
	}

	&:last-child {
		.level-image {
			img {
				width: 58px;
			}
		}
	}

	&.completed {
		background-image: none;
		background-color: rgba($color-white, .25);
		box-shadow: none;
	}

	&.inactive {
		background-image: none;
		background-color: rgba($color-white, .25);
		box-shadow: none;
	}

	.icon-check {
		position: absolute;
		top: 8px;
		right: 9px;
		width: 20px;
		z-index: 1;
	}

	.border-completed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 4px solid $color-correct;
	}

	.level-image {
		flex: 1;
		display: flex;

		img {
			max-width: 100%;
			margin: auto;
		}
	}

	.level-title {
		font-size: 16px;
		line-height: 19px;
		font-weight: 500;
		color: $color-white;
	}
}
