@import '../../../../../variables';

.fill-shape-game {
	.game-wrapper {
		margin-left: 20px;
		margin-right: 20px;
		padding-bottom: 14px;
		padding-top: 13px;
		padding-left: 25px;
		padding-right: 25px;
		background-color: $color-white;
		box-shadow: 0px 8px 20px 0px #00000059;
		margin-bottom: 25px;

		.shapes-image {
			display: flex;
			justify-content: center;
			margin-bottom: 13px;

			img {
				display: block;
				max-width: 100%;
				max-height: 227px;
			}
		}

		.answers-list {
			display: flex;
			gap: 7px;
			justify-content: center;

			.answer {
				cursor: pointer;
				position: relative;
				max-width: 55px;

				&.correct {
					.answer-border {
						border-color: $color-correct;
					}
				}

				&.incorrect {
					.answer-border {
						border-color: $color-incorrect;
					}
				}

				img {
					display: block;
					max-width: 100%;
				}

				.answer-border {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					z-index: 9;
					border-width: 4px;
					border-color: transparent;
					border-style: solid;
					border-radius: 29%;
					transition: all ease-in-out .2s;
				}
			}
		}
	}
}
