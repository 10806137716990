@import '../../../../variables';

header {
	height: 55px;

	.menu-bg-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($color-bg, .7);
		z-index: 100;
	}

	.nav-control {
		height: 55px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 10px;
		background-color: $color-main;
		box-shadow: 0px 4px 6px 0px #00000033;

		.btn-nav {
			position: absolute;
			left: 8px;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			z-index: 201;

			a {
				display: block;
			}

			img {
				display: block;
				max-width: 100%;
			}
		}

		.logo-header {
			max-width: 207px;
			padding-top: 9px;
			margin: 0 auto;
		}
	}

	.play-for {
		position: absolute;
		right: 5px;
		top: 0;

		svg {
			position: relative;
			width: 53px;
			transform: rotate(180deg);
			margin-bottom: -1px;
		}

		.banner-content {
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 45%;
			font-size: 12px;
			line-height: 12px;
			text-align: center;
			text-transform: uppercase;
			width: 75px;

			span {
				font-size: 10px;
				line-height: 10px;
				font-weight: 600;
			}

			strong {
				display: block;
				font-size: 12px;
				line-height: 12px;
				font-weight: 800;
			}
		}
	}
}
