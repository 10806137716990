@import '../../../variables';

.confirm-component {
	padding-left: 20px;
	padding-right: 20px;

	.title {
		margin-bottom: 29px;
	}

	.prize-confirm {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 211px;
		height: 89px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		background-image: url("../../../resources/img/bg-border.png");
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;

		&.small {
			width: 146px;
			height: 89px;
			background-image: url("../../../resources/img/bg-border-small.png");
		}

		h3 {
			margin-bottom: -26px;
			font-size: 44px;
			font-weight: 700;
			line-height: 28.6px;
			color: $color-text;
		}

		p {
			display: block;
			width: 100%;
			font-size: 28px;
			line-height: 18px;
			font-weight: 400;
		}
	}

	.trophy {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 8px;
	}

	.btn-group {
		width: 251px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin: 0 auto;

		> a {
			display: block;
		}
	}

	.notice {
		display: block;
		max-width: 217px;
		margin-top: 16px;
		margin-left: auto;
		margin-right: auto;
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
	}
}
