@import '../../variables';

.avatar-progress {
	display: inline-block;
	color: #fff;
	border-radius: 100%;
	padding: 10px;
	line-height: 0;
	position: relative;

	svg {
		height: 120px;
		width: 120px;
		transform: scale(1.1) rotate(-90deg);
	}

	img {
		position: absolute;
		right: 0;
		margin: auto;
		top: 9px;
		z-index: -1;
		max-width: 120px;
		left: -1px;
		border-radius: 100%;
	}
}
