@import "../../variables.scss";

.transactions-component {
	h1 {
		margin-bottom: 20px;
	}

	.no-transactions-text {
	}

	.list {
		.transaction {
			padding: 10px;
			border-bottom: 1px solid $color-text;

			p {
				margin: 0;
				font-size: 14px;
				color: $color-text;

				&.success {
					color: $color-valid;
				}

				&.fail {
					color: $color-error
				}
			}
		}
	}
}
