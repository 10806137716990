@import '../../../variables';

nav {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 298px;
	overflow: hidden;
	margin-left: -100%;
	transition: margin-left .2s ease-in;
	z-index: 200;

	&.active {
		margin-left: 0;

		.nav-inner {
			overflow-y: auto;

			ul {
				left: 0;
				opacity: 1;
			}
		}
	}

	.menu-notice {
		display: block;
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 60px;
	}

	.nav-inner {
		height: 100%;
		width: 100%;
		background-color: $color-main;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-shadow: 4px 0px 10px 0px #00000073;
		padding-top: 136px;
		padding-left: 26px;
		padding-bottom: 42px;

		ul {
			display: flex;
			flex-direction: column;
			gap: 15px;
			position: relative;
			left: -50px;
			text-align: left;
			opacity: 0;
			transition: all .2s ease-in;
			transition-delay: .2s;

			li {

				&.restricted {
					a {
						display: inline-block;
						background-image: url('img/icon-locker.png');
						background-repeat: no-repeat;
						background-position: left center;
						background-size: 16px 20px;
						padding-left: 20px;
						opacity: .4;
					}
				}

				a {
					display: inline-block;
					font-size: 20px;
					line-height: 23.7px;
					font-weight: 400;
					text-decoration: none;
					color: $color-text;
				}
			}
		}

		.menu-secondary {
			li {
				a {
					font-size: 16px;
					line-height: 19px;
					font-weight: 400;
				}
			}
		}
	}
}


@media (max-height: 574px) {
	nav {
		.nav-inner {
			ul {
				padding-top: 58px;

				li {
					padding: 1.5% 0;
				}
			}
		}
	}
}
