@import "../../variables.scss";

.levels-component {
	padding-bottom: 30px;

	.levels-image {
		display: block;
		margin: 0 auto 21px;
	}

	.levels-btn {
		display: block;
		width: 251px;
		margin-top: 35px;
		margin-left: auto;
		margin-right: auto;
	}
}
