@import '../../../../../variables';

.games-slider {
	.slick-list {
		box-sizing: initial;
	}

	.slick-slide {
		padding: 0 6px;
		box-sizing: border-box;
	}

	.slider-nav {
		margin-bottom: 18px;

		.slick-track {
			display: flex;
			align-items: center;
		}

		.slick-list {
			padding-bottom: 3px!important;
		}

		.item-nav {
			text-align: center;
		}

		.item-nav-title {
			font-size: 16px;
			font-weight: 500;
			line-height: 1;
			letter-spacing: -0.6px;
			color: $color-white;
		}

		.slick-current {
			.item-nav {
				padding: 5px 9px;
				background: rgba(0,0,0,.5);
				border-radius: 6px;
				position: relative;

				.item-nav-title {
					font-size: 18px;
					font-weight: 700;
					line-height: 21px;
				}

				&::after {
					content: "";
					position: absolute;
					width: 30px;
					height: 3px;
					background: $color-white;
					bottom: -3px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	.slider-main {
		.slick-prev, .slick-next {
			z-index: 1;
			width: 54px;
			height: 54px;
			background-color: $color-correct;
			box-shadow: 0 4px 16px 0 rgba(0,0,0,.2);

			&::before {
				content: "";
				display: block;
				text-align: center;
				position: absolute;
				inset: 0;
				margin: auto;
				width: 23px;
				height: 23px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url(../img/icon-right.png);
				opacity: 1;
			}
		}

		.slick-prev {
			left: 0;
			border-radius: 0 32px 32px 0;

			&::before {
				transform: rotate(180deg);
			}

		}

		.slick-next {
			right: 0;
			border-radius: 32px 0 0 32px;
		}

		.slick-slide {
			padding: 12px!important;
			transition: all .2s ease-in;
		}

		.slick-current {
			padding: 0!important;
			//transform: scale(1.1);
		}
	}

	.item {
		a {
			display: block;
			position: relative;
			padding-bottom: 105.5%;
			background-color: $color-white;
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto 100%;
			border-radius: 30px;

			.btn-play {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 8%;
				font-size: 20px;
				line-height: 1;
				font-weight: 700;
				color: $color-white;
				padding: 6px;
				border-radius: 20px;
				min-width: 171px;
				min-height: 36px;
				background-color: $color-red;
				box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;

				&.inverted {
					background-color: $color-white;
					color: $color-red;
					box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
				}

				&.completed {
					background-color: $color-correct;
					color: $color-white;
					box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);

					&::after {
						background-image: url(../img/icon-completed.png);
						background-repeat: no-repeat;
						background-position: center;
						display: inline-block;
						content: "";
						min-width: 20px;
						width: 20px;
						height: 20px;
						background-size: 100%;
						position: relative;
						margin-left: 7px;
					}
				}

				&.locked {
					opacity: .8;
				}
			}
		}
	}
}
