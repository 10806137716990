@import '../../../../../variables';

.quiz {
	.title {
		margin-bottom: 30px;

		p {
			margin-bottom: 0;
		}
	}

	.quiz-area {
		padding-left: 20px;
		padding-right: 20px;
	}

	.question-title {
		max-width: 322px;
		min-height: 130px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 37px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding: 16px 0;
		background-image: url(../img/question-bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.question {
			width: 83%;
			font-size: 16px;
			font-weight: 500;
			color: $color-text;
		}
	}

	.answer {
		position: relative;
		margin-bottom: 18px;
		text-align: center;

		&:last-child {
			margin-bottom: 0;
		}

		&.selected {
			&.correct {
				button {
					background-color: $color-correct;
					color: $color-white;
				}
			}

			&.incorrect {
				button {
					background-color: $color-red;
					color: $color-white;
				}
			}
		}

		button {
			width: 251px;
			min-height: 42px;
			border: 0;
			background-color: $color-white;
			font-size: 20px;
			font-weight: 600;
			color: $color-main;
			margin-left: auto;
			margin-right: auto;
			border-radius: 50px;
			padding: 6px;
			box-shadow: 0 0 0 4px rgba($color-white, 0.4);

			&:focus {
				outline: 0;
			}
		}
	}

	.total-questions {
		margin-top: 15px;
		text-align: center;
	}

	.answer-transition {
		position: relative;
		opacity: 0;
		left: -1000px;
	}

	.answer-transition-enter {
		position: relative;
		opacity: 0;
		left: -500px;
	}
	.answer-transition-enter-active {
		opacity: 1;
		transition: all 200ms ease-in;
		left: 0;
	}
	.answer-transition-exit {
		opacity: 1;
		left: 0;
	}
	.answer-transition-exit-active {
		opacity: 0;
		left: -500px;
		transition: all 200ms ease-in;
	}

	.question-transition {
		opacity: 0;
	}

	.question-transition-enter {
		opacity: 0;
	}

	.question-transition-enter-active {
		opacity: 1;
		transition: all 200ms ease-in;
	}

	.question-transition-exit {
		opacity: 1;
	}

	.question-transition-exit-active {
		opacity: 0;
		transition: all 200ms ease-in;
	}
}

@media (max-height: 550px) {
	.quiz {
		.title {
			margin-bottom: 7px;

			p {
				margin-top: 5px;
			}
		}

		.question {
			font-size: 16px;
			min-height: 54px;
			margin-bottom: 7px;
		}

		.answers-list {
			min-height: 170px;
		}

		.answer {
			margin-bottom: 6px;

			button {
				height: 38px;
				font-size: 16px;
			}
		}

		.total-questions {
			margin-top: 7px;
		}
	}
}
