@import "../../../variables";

.edit-profile-component{
    padding: 18px;

   .title {
		 h1 {
			 color: $color-white;
			 text-align: center;

			 &:last-of-type {
				 padding-top: 22px;
			 }
		 }

		 p {
			 color: $color-white;
			 text-align: center;
			 padding-bottom: 22px;
		 }
	 }

		form {
			button[type="submit"] {
				appearance: none;
				width: 100%;
				border: none;
				padding: 0;
				background-color: transparent;

				&:focus {
					outline: 0;
				}
			}
		}

    .avatar-list{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
				margin-top: 8px;
        margin-bottom: 10px;

			label {
				margin-left: 5px;
				margin-right: 5px;
				margin-bottom: 5px;
			}

        input{
            display: none;

            &:checked + .avatar{
                background-color: $color-main;
            }
        }
    }
}

@media (max-height: 574px) {
	.edit-profile-component {
		padding-top: 6px;
		padding-bottom: 10px;

		.title {
			p {
				padding-bottom: 7px;
				margin-top: 0;
			}

			h1 {
				&:last-of-type {
					padding-top: 10px;
				}
			}
		}

		.avatar-list {
			margin-bottom: 3px;
		}
	}
}
